<template>
    <div class="page">
        <van-nav-bar :border="false" :fixed="false" :title="$t('选择语言')" left-arrow @click-left="onClickLeft"/>
        <div class="wrapper">
            <van-skeleton :row="5" :loading="loading">
                <div class="bs-panel lang-panel">
                    <div class="cells">
                        <van-cell center
                                  :title="lang.title"
                                  v-for="lang in langs"
                                  :key="`lang${lang.lang}`"
                                  @click="changeLocale(lang.lang)"
                        >
                            <template #icon>
                                <img :src="lang.flag" class="lan-icon" width="40" style="margin-right: 20px;box-shadow: 0px 5px 12px rgba(75,98,157,0.11);"
                                     alt/>
                            </template>
                            <template #right-icon>
                                <van-icon name="success" v-if="lang.lang == $i18n.locale"
                                        size="16" color="#fff"
                                        style="background-color: #00ff8a;border-color: #00ff8a;border-radius: 100%;padding: 3px"
                                />
                            </template>
                        </van-cell>
                    </div>
                </div>
            </van-skeleton>
        </div>
    </div>
</template>
<script>
    import helper from "@/utils/helper";

    export default {
        components: {},
        data() {
            return {
                loading: false,
                langs: [],
                select_lang_zh_cn: false,
            };
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            
            getData() {
                this.loading = true;
                this.$axios
                    .get(this.$api_url.get_langs)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.langs = res.data.data;
                            this.langs = this.langs.filter((item) => {
                                console.log(item);
                                console.log(this.select_lang_zh_cn);
                                if (!(this.select_lang_zh_cn > 0)) {
                                    return item.lang != 'zh-cn' && item.lang != 'zh_cn' && item.lang != 'cn';
                                }
                                return true;
                            });
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
            async getConfig() {
                await this.$axios.get(this.$api_url.get_config).then(res => {
                    if (res.data.code == 0) {
                        var data = res.data.data;
                        console.log(data)
                        for (const key in data) {
                            localStorage.setItem(key, data[key]);
                        }
                        window._vue.$emit('getWhs');
                    }
                });
            },
            changeLocale(lang) {
                this.$i18n.locale = lang
                helper.setLang(lang)
                let token = helper.getToken();
                if(token !== '' && token !== null && token !== undefined){
                    this.$axios.post(this.$api_url.set_lang, {lang: lang})
                        .then((res) => {
                            this.getConfig()
                        });
                }
                this.$router.go(-1);
            }
        },
        mounted() {
            this.getData()
            this.select_lang_zh_cn = localStorage.getItem('app.select_lang_zh_cn') || 1;
        }
    };
</script>
